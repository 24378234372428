import lang from '@/plugins/i18n'

export const handlerTypes = [
  {text: lang.t('shared.choose'), value: null},
  {text: lang.t('paymentConfigs.PASARGAD_IPG'), value: 'PASARGAD_IPG', payType: 'IPG'},
  {text: lang.t('paymentConfigs.MELLAT_IPG'), value: 'MELLAT_IPG', payType: 'IPG'},
  {text: lang.t('paymentConfigs.PARSIAN_IPG'), value: 'PARSIAN_IPG', payType: 'IPG'},
  {text: lang.t('paymentConfigs.ZARRINPAL_IPG'), value: 'ZARRINPAL_IPG', payType: 'IPG'},
  {text: lang.t('paymentConfigs.SAMAN_IPG'), value: 'SAMAN_IPG', payType: 'IPG'},
  {text: lang.t('paymentConfigs.KIPO_IPG'), value: 'KIPO_IPG', payType: 'IPG'},
  {text: lang.t('paymentConfigs.ISIRANCREDIT_IPG'), value: 'ISIRANCREDIT_IPG', payType: 'IPG'},
  {text: lang.t('paymentConfigs.ISIRANDEBIT_IPG'), value: 'ISIRANDEBIT_IPG', payType: 'IPG'},
  {text: lang.t('paymentConfigs.PAYPOD_IPG'), value: 'PAYPOD_IPG', payType: 'IPG'},
  {text: lang.t('paymentConfigs.CASH'), value: 'CASH', payType: 'CASH'},
  {text: lang.t('paymentConfigs.SEPPAYMENT_MPG'), value: 'SEPPAYMENT_MPG', payType: 'MPG'},
  {text: lang.t('paymentConfigs.PASARGAD_POS'), value: 'PASARGAD_POS', payType: 'POS'},
  {text: lang.t('paymentConfigs.PARSIAN_POS'), value: 'PARSIAN_POS', payType: 'POS'},
  {text: lang.t('paymentConfigs.SAMAN_POS'), value: 'SAMAN_POS', payType: 'POS'},
  {text: lang.t('paymentConfigs.CAFE_BAZAR_IPG'), value: 'CAFE_BAZAR_IPG', payType: 'IPG'},
  {text: lang.t('paymentConfigs.VANDAR_IPG'), value: 'VANDAR_IPG', payType: 'IPG'}
]

export const payTypes = [
  {text: lang.t('shared.choose'), value: null},
  {text: lang.t('paymentConfigs.POS'), value: 'POS'},
  {text: lang.t('paymentConfigs.CASH'), value: 'CASH'},
  {text: lang.t('paymentConfigs.IPG'), value: 'IPG'},
  {text: lang.t('paymentConfigs.MPG'), value: 'MPG'},
  {text: lang.t('paymentConfigs.ORG'), value: 'ORG'}
]
