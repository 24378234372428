<template>
  <div>
    <search-form>
      <div class="d-inline-flex flex-wrap mb-4">
        <c-input v-model="search.id" label="ID" class="ma-5" dense hide-details />

        <c-input v-model="search.name" :label="$t('shared.name')" class="ma-5" dense hide-details />
      </div>

      <div class="mx-5 d-inline-flex w-100">
        <v-btn color="success" dark @click="filtering" small :loading="progressing">
          <v-icon small class="me-1"> mdi-filter </v-icon>
          {{ $t('shared.applyFilter') }}
        </v-btn>
      </div>
    </search-form>

    <div class="d-flex mb-4">
      <v-spacer />
      <v-btn color="primary" dark to="create" small>
        <v-icon small> mdi-plus </v-icon>
        {{ $t('shared.add') }}
      </v-btn>
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="context"
      @update:options="filtering"
      :server-items-length="count"
      :loading="progressing"
      :footer-props="$config.tableFooterProps"
      sort-by="id"
      must-sort
      class="elevation-2"
    >
      <template #[`item.actions`]="{item}">
        <div class="d-flex justify-end">
          <v-btn small fab text color="info" :to="`edit/${item.id}`">
            <v-icon> mdi-pencil </v-icon>
          </v-btn>

          <v-btn small fab text color="error" @click="removeItem(item)">
            <v-icon> mdi-delete </v-icon>
          </v-btn>
        </div>
      </template>

      <template #[`item.payType`]="{item}">
        {{ getPayType(item) }}
      </template>

      <template #[`item.handlerType`]="{item}">
        {{ getHandlerType(item) }}
      </template>

      <template #[`item.active`]="{item}">
        <v-icon v-if="item.active" color="success"> mdi-check </v-icon>
        <v-icon v-else color="secondary"> mdi-close </v-icon>
      </template>

      <template v-slot:no-data>
        {{ $t('shared.noRecords') }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {handlerTypes, payTypes} from '@/enums/paymentConfigs'
export default {
  name: 'Index',
  props: {
    type: {type: String}
  },
  data() {
    return {
      progressing: false,
      count: 1,
      context: {},
      items: [],
      headers: [
        {text: 'ID', value: 'id'},
        {
          text: this.$t('shared.name'),
          value: 'name'
        },
        {
          text: this.$t('paymentConfigs.payType'),
          value: 'payType'
        },
        {
          text: this.$t('paymentConfigs.handlerType'),
          value: 'handlerType'
        },
        {
          text: this.$t('shared.active'),
          value: 'active'
        },
        {text: this.$t('shared.actions'), value: 'actions', sortable: false}
      ],
      search: {
        id: null,
        pin: null
      }
    }
  },
  methods: {
    getPayType(item) {
      return payTypes.find((type) => type.value === item.payType)?.text
    },
    getHandlerType(item) {
      return handlerTypes.find((type) => type.value === item.handlerType)?.text
    },
    filter(isCount) {
      const filter = {}
      if (!isCount) {
        filter['sort[0].column'] = this.context.sortBy[0]
        filter['sort[0].type'] = this.context.sortDesc[0] ? 'ASCENDING' : 'DESCENDING'
        filter.size = this.context.itemsPerPage
        filter.page = this.context.page
      }

      const searchItems = Object.keys(this.search)
      searchItems.forEach((item) => {
        const val = this.search[item]
        if (val || val === false || val === 0) {
          filter[item] = val
        }
      })
      return filter
    },
    getItems(filter) {
      return this.$api.paymentConfigs.getAll(filter).then((res) => {
        this.items = res
      })
    },
    getCount(filter) {
      return this.$api.paymentConfigs.count(filter).then((res) => {
        this.count = res
      })
    },
    filtering() {
      this.progressing = true
      Promise.all([this.getItems(this.filter()), this.getCount(this.filter(true))])
        .then((res) => {
          this.progressing = false
        })
        .catch((err) => {
          this.$showError(err)
        })
    },
    async removeItem(item) {
      try {
        if (!(await this.$confirm())) return

        await this.$api.paymentConfigs.delete(item.id)
        this.filtering()
        this.$showSuccess(this.$t('paymentConfigs.paymentConfigRemoved'))
      } catch (error) {
        this.$showError(error)
      }
    }
  }
}
</script>
